<template>
    <section
        class="carousel-large overflow-hidden pb-12 lg:pb-20"
        :class="halfBackground ? 'carousel-large--half-background mt-12 lg:mt-20' : 'bg-indigo pt-8 lg:pt-12'"
    >
        <div class="container grid-container relative">
            <h2
                v-if="heading"
                class="h3 mb-4 text-center"
                :class="{'text-white': !halfBackground}"
            >{{ heading }}</h2>

            <vue-glide
                :gap="0"
                :per-view="slidesPerView"
                :breakpoints="breakpoints"
                :bound="true"
                v-model="currentSlideId"
            >
                <slot></slot>

                <template slot="control">
                    <button
                        v-show="currentSlideId > 0"
                        data-glide-dir="<"
                        class="arrow-btn arrow-btn--previous absolute top-1/2 transform -translate-y-1/2 -left-20"
                        :aria-label="ariaLabelPrevious"
                    ></button>
                    <button
                        v-show="currentSlideId < slidesCount-1"
                        data-glide-dir=">"
                        class="arrow-btn arrow-btn--next absolute top-1/2 transform -translate-y-1/2 -right-20"
                        :aria-label="ariaLabelNext"
                    ></button>
                </template>
            </vue-glide>

            <div class="flex lg:hidden justify-center mt-2">
                <folio :current-step="currentSlideId" :length="slidesCount" @updateSlide="updateSlideHandler"></folio>
            </div>
        </div>
    </section>
</template>

<script>
import Folio from "./Folio";

export default {
    name: "CarouselLarge",

    props: {
        heading: {
            type: String,
            default: null,
        },
        slidesCount: {
            type: Number,
            required: true,
        },
        slidesPerView: {
            type: Number,
            default: 3,
        },
        halfBackground: {
            type: Boolean,
            default: true,
        },
        ariaLabelPrevious: {
            type: String,
            required: true,
        },
        ariaLabelNext: {
            type: String,
            required: true,
        },
    },

    components: {
        Folio,
    },

    data() {
        return {
            breakpoints: {
                992: {
                    perView: 1,
                    gap: 8,
                },
            },
            currentSlideId: 0,
        }
    },

    methods: {
        updateSlideHandler(key) {
            this.currentSlideId = key;
        }
    },
}
</script>

<style scoped lang="scss">
.carousel-large {
    &--half-background {
        background: linear-gradient(0deg, theme('colors.indigo.DEFAULT') 50%, theme('colors.white') 50%);
    }
}
</style>

<style lang="scss">
.carousel-large {
    .glide__track {
        overflow: visible;
    }
}
</style>
