import "./assets/styles/main.scss";
require('typeface-dm-sans');

import Vue from "vue";
import CsrfInput from "@/app/components/CsrfInput";
import HeaderNav from "@/app/components/HeaderNav";
import SubMaterial from "@/app/components/SubMaterial";
import CarouselLarge from "@/app/components/CarouselLarge";
import CarouselProductions from "@/app/components/CarouselProductions";
import Anchor from "@/app/components/Anchor";
import AnchorIntersector from "@/app/components/AnchorIntersector";
import AnchorsMenu from "@/app/components/AnchorsMenu";
import AnimateIn from "@/app/animations/AnimateIn";
import DisplayText from "@/app/animations/DisplayText";
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'

Vue.use(VueGlide);

new Vue({
    el: "#app",
    components: {
        CsrfInput,
        HeaderNav,
        SubMaterial,
        CarouselLarge,
        CarouselProductions,
        AnimateIn,
        DisplayText,
        Anchor,
        AnchorIntersector,
        AnchorsMenu,
    },
});
