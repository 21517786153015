<template>
    <nav :aria-label="ariaLabelNavigation" class="header-overlay-mobile fixed inset-0 w-screen h-screen bg-indigo-dark text-white">
        <div class="flex justify-between border-b border-gray-500 py-8 px-4">
            <button v-if="!isFirstLevel" @click="$emit('back')">
                <img src="~@/static/images/arrow-left-white.svg" alt="Retour">
            </button>

            <slot></slot>

            <button class="w-5 h-5" @click="$emit('close')">
                <img src="~@/static/images/cross.svg" alt="Fermer">
            </button>
        </div>

        <div class="flex flex-col px-4 overflow-scroll">
            <template v-for="(item, i) in items">
                <a
                    :key="i"
                    v-if="!item.hasSubmenu"
                    :href="item.link.url"
                    :target="item.link.target"
                    class="header-overlay-mobile__arrow-item"
                    :class="{'border-b border-gray-700': i < items.length-1}"
                >
                    <span class="flex-1 flex flex-col py-4">
                        <span class="body-1">
                            {{ item.link.label }}
                        </span>

                        <span class="caption text-gray-400 mt-1" v-if="item.text">
                            {{ item.text }}
                        </span>
                    </span>
                </a>

                <div
                    :key="i"
                    v-if="item.hasSubmenu"
                    class="flex"
                    :class="{'border-b border-gray-700': i < items.length-1}"
                >
                    <div
                        class="header-overlay-mobile__arrow-item"
                        @click="currentOverlayId = i"
                    >
                        <div class="flex-1 text-base py-4">{{ item.text }}</div>
                    </div>

                    <fade>
                        <header-overlay-mobile
                            v-if="currentOverlayId === i"
                            :items="item.submenu"
                            :is-first-level="false"
                            @back="currentOverlayId = null"
                            @close="$emit('close')"
                        >
                            <div>
                                {{ item.text }}
                            </div>
                        </header-overlay-mobile>
                    </fade>
                </div>
            </template>
        </div>
    </nav>
</template>

<script>
import Fade from "../transitions/Fade";

export default {
    name: "HeaderOverlayMobile",

    components: {
        Fade,
    },

    props: {
        isFirstLevel: {
            type: Boolean,
            default: true,
        },
        items: {
            type: Array,
            required: true,
        },
        ariaLabelNavigation: {
            type: String,
            raquired: true,
        },
    },

    data() {
        return {
            currentOverlayId: null
        }
    },
}
</script>

<style scoped lang="scss">
    .header-overlay-mobile {
        &__arrow-item {
            @apply flex items-center justify-between w-full no-underline hover:opacity-70 transition-opacity duration-200 cursor-pointer;

            &::after {
                @apply mx-3 bg-cover bg-center;
                content: '';
                width: 1rem;
                height: 0.6875rem;
                background-image: url('~@/static/images/arrow-right-red.svg');
            }
        }
    }
</style>
