<template>
    <div class="sub-material container lg:px-8 mb-6">
        <article class="relative bg-white px-4 lg:px-24 py-10 text-black">
            <anchor-intersector :anchor-id="anchor" root-margin="0px 0px -90% 0px">
                <div :id="anchor" class="absolute -top-36 lg:-top-40"></div>
            </anchor-intersector>

            <h2 class="h5">{{ heading }}</h2>

            <button
                class="sub-material__button absolute top-0 right-0 w-10 h-10 flex items-center justify-center bg-primary focus:outline-none focus:ring focus:ring-red-light"
                :class="{'sub-material__button--opened': isOpened}"
                :aria-label="currentArialLabel"
                @click="isOpened = !isOpened"
            ></button>

            <accordion v-model="isOpened">
                <slot></slot>
            </accordion>
        </article>
    </div>
</template>

<script>
import Accordion from "./Accordion";
import AnchorIntersector from "./AnchorIntersector";

export default {
    name: "SubMaterial",

    components: {
        Accordion,
        AnchorIntersector,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
        anchor: {
            type: String,
            required: true,
        },
        ariaLabels: {
            type: Object,
            required: true,
        },
        isOpenedAtInit: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isOpened: false,
        }
    },

    computed: {
        currentArialLabel() {
            return this.isOpened ? this.ariaLabels.close : this.ariaLabels.open;
        }
    },

    mounted() {
        this.isOpened = this.isOpenedAtInit;
    },
}
</script>

<style scoped lang="scss">
    .sub-material {
        &__button {
            &::after {
                @apply w-3 h-2 bg-center bg-cover transform rotate-180 transition-transform duration-300;
                content: '';
                background-image: url('~@/static/images/chevron-top-white.svg');
            }

            &--opened {
                &::after {
                    @apply rotate-0;
                }
            }
        }
    }
</style>
