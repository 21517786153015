<template>
    <section class="anchors-menu sticky z-20 bg-gray-100 flex justify-center border-b border-gray-200">
        <div class="overflow-auto">
            <ul class="flex lg:justify-center items-center">
                <li v-for="(item, key) in items" class="flex-shrink-0" :key="key">
                    <a
                        :href="item.anchor"
                        :class="{ 'anchors-menu__link--active': item.anchor === `#${currentAnchor}` }"
                        class="anchors-menu__link relative inline-flex caption no-underline p-4 text-gray-600 transition-all duration-200 hover:opacity-100 hover:text-black hover:no-underline"
                    >
                        {{ item.text }}
                    </a>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import { EventBus } from "../services/EventBus";

export default {
    name: "AnchorsMenu",

    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            currentAnchor: null,
        }
    },

    mounted() {
        EventBus.$on('updateAnchorsMenu', this.updateAnchorsMenuHandler)
    },

    methods: {
        updateAnchorsMenuHandler(anchorId) {
            this.currentAnchor = anchorId;
        },
    },
}
</script>

<style scoped lang="scss">

.anchors-menu {
    top: 5.125rem;

    @screen lg {
        top: 5.875rem;
    }

    &__link {
        &--active {
            @apply text-black;
            box-shadow: 0px -2px 0px 0px rgb(227, 29, 20) inset;
        }
    }
}
</style>
