<template>
    <intersector @enter="enterHandler">
        <div>
            <slot></slot>
        </div>
    </intersector>
</template>

<script>
import Intersector from "./Intersector";
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";

export default {
    name: "DisplayText",

    props: {
        delay: {
            type: Number,
            default: 0.5,
        },
        duration: {
            type: Number,
            default: 0.8,
        },
    },

    components: {
        Intersector,
    },

    data() {
        return {
            splittedText: null
        }
    },

    mounted() {
        this.splittedText = new SplitText(this.$el.querySelectorAll('h1, h2, h3, h4, h5, p'), {type: 'lines, words', linesClass: 'overflow-hidden'});

        gsap.set(this.splittedText.words, {
            opacity: 0,
            y: 50,
            rotate: 5,
        });
    },

    methods: {
        enterHandler() {
            gsap.to(this.splittedText.words, {
                opacity: 1,
                y: 0,
                rotate: 0,
                ease: 'power1.out',
                delay: this.delay,
                duration: this.duration,
            });
        },
    }
}
</script>
