<template>
    <intersect :threshold="[0.5]" :root-margin="rootMargin" @enter="enterHandler">
        <slot></slot>
    </intersect>
</template>

<script>
import Intersect from "vue-intersect";
import { EventBus } from "../services/EventBus"

export default {
    name: "AnchorIntersector",

    props: {
        anchorId: {
            type: String,
            required: true,
        },
        rootMargin: {
            type: String,
            default: '0px 0px -70% 0px',
        },
    },

    components: {
        Intersect,
    },

    methods: {
        enterHandler() {
            EventBus.$emit('updateAnchorsMenu', this.anchorId);
        },
    },
}
</script>
