<template>
    <intersect :threshold="[0.5]" @enter="enterHandler">
        <slot></slot>
    </intersect>
</template>

<script>
import Intersect from 'vue-intersect';

export default {
    name: "Intersector",

    components: {
        Intersect,
    },

    data() {
        return {
            hasBeenVisible: false,
        }
    },

    methods: {
        enterHandler() {
            if (this.hasBeenVisible) {
                return;
            }

            this.hasBeenVisible = true;
            this.$emit('enter');
        },
    },
}
</script>
