<template>
    <section class="carousel-productions relative my-12 lg:my-20 overflow-hidden">
        <div class="container grid-container relative">
            <div class="grid-row">
                <div class="grid-col w-full lg:w-1/3 lg:flex lg:flex-col lg:justify-center lg:relative lg:z-10 bg-white">
                    <slot name="text"></slot>
                </div>
                <div class="grid-col w-full lg:w-1/2 relative mt-6 lg:mt-0">
                    <vue-glide
                        v-model="currentSlideId"
                        :per-view="1"
                        :gap="32"
                        :breakpoints="breakpoints"
                    >
                        <slot name="slides"></slot>

                        <template slot="control">
                            <button
                                v-show="currentSlideId < slidesCount-1"
                                data-glide-dir=">"
                                class="arrow-btn arrow-btn--black arrow-btn--next absolute top-1/2 transform -translate-y-1/2 -right-32"
                                :aria-label="ariaLabelNext"
                            ></button>
                        </template>
                    </vue-glide>


                    <button
                        v-show="currentSlideId === slidesCount-1"
                        class="arrow-btn arrow-btn--black arrow-btn--previous absolute top-1/2 transform -translate-y-1/2 -right-32 mr-4"
                        :aria-label="ariaLabelPrevious"
                        @click="currentSlideId = 0"
                    ></button>
                </div>

                <div class="grid-col w-full flex lg:hidden justify-center mt-2">
                    <folio :current-step="currentSlideId" :length="slidesCount" @updateSlide="updateSlideHandler"></folio>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Folio from "./Folio";

export default {
    name: "CarouselProductions",

    props: {
        slidesCount: {
            type: Number,
            required: true,
        },
        ariaLabelPrevious: {
            type: String,
            required: true,
        },
        ariaLabelNext: {
            type: String,
            required: true,
        },
    },

    components: {
        Folio
    },

    data() {
        return {
            currentSlideId: 0,

            breakpoints: {
                992: {
                    gap: 8,
                },
            },
        }
    },

    methods: {
        updateSlideHandler(key) {
            this.currentSlideId = key;
        }
    },
}
</script>

<style scoped lang="scss">
.carousel-productions {
    @screen lg {
        &::before {
            content: '';
            @apply absolute inset-y-0 left-0 bg-white z-10 w-1/4;
        }
    }
}
</style>

<style lang="scss">
.carousel-productions {
    .glide__track {
        overflow: visible;
    }

    .glide__slide {
        @apply opacity-70 transition-opacity duration-100;

        &--active {
            @apply opacity-100;
        }
    }
}
</style>
