<template>
    <header class="header-nav fixed top-0 inset-x-0 z-30 text-white">
        <div class="header-nav__banner flex justify-between items-center p-4 lg:px-8 lg:pt-4 lg:pb-0 transition-colors duration-300 border-b" :class="{'bg-indigo': hasDarkBackground}">
            <a :href="currentSiteUrl">
                <img class="header-nav__logo" :src="logo.url" :alt="logo.alt">
            </a>

            <button class="lg:hidden w-6 h-6 flex items-center" @click="isOverlayOpened = true">
                <img src="~@/static/images/burger.svg" alt="Menu">
            </button>

            <nav :aria-label="ariaLabelNavigation" class="hidden lg:flex lg:flex-col lg:items-end">
                <div class="flex items-center">
                    <a
                        v-if="emailLink"
                        :href="emailLink.url"
                        :target="emailLink.target"
                        class="font-medium text-xs no-underline mr-4"
                    >{{ emailLink.label }}</a>
                    <span v-if="phoneLink && emailLink">|</span>
                    <a
                        v-if="phoneLink"
                        :href="phoneLink.url"
                        :target="phoneLink.target"
                        class="font-medium text-xs no-underline ml-4"
                    >{{ phoneLink.label }}</a>
                </div>

                <div class="flex mt-4">
                    <template v-for="(item, i) in items">
                        <a
                            :key="i"
                            v-if="!item.hasSubmenu"
                            :href="item.link.url"
                            :target="item.link.target"
                            class="header-nav__desktop-item relative text-base no-underline ml-10"
                        >{{ item.link.label }}</a>

                        <div
                            :key="i"
                            v-if="item.hasSubmenu"
                            class="header-nav__desktop-item header-nav__submenu-container relative ml-10 pb-4"
                        >
                            <span class="text-base cursor-pointer">{{ item.text }}</span>

                            <ul class="header-nav__submenu absolute top-full left-1/2 transform -translate-x-1/2 bg-indigo-dark flex flex-col list-none py-6">
                                <li
                                    v-for="(submenuItem, j) in item.submenu"
                                    :key="`${i}-${j}`"
                                    class="my-2"
                                    :class="submenuItem.text ? 'px-8 text-center' : 'px-10'"
                                >
                                    <a
                                        :href="submenuItem.link.url"
                                        :target="submenuItem.link.target"
                                        class="flex flex-col no-underline text-nowrap hover:no-underline"
                                    >
                                        <span class="body-1">{{ submenuItem.link.label }}</span>

                                        <span v-if="submenuItem.text" class="caption text-gray-400">{{ submenuItem.text }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </template>
                </div>
            </nav>
        </div>
        <fade>
            <header-overlay-mobile
                v-if="isOverlayOpened"
                class="lg:hidden"
                :items="items"
                :email-link="emailLink"
                :phone-link="phoneLink"
                :aria-label-navigation="ariaLabelNavigation"
                @close="isOverlayOpened = false"
            >
                <div class="flex items-center">
                    <a
                        v-if="emailLink"
                        :href="emailLink.url"
                        :target="emailLink.target"
                        class="font-medium text-xs no-underline mr-2"
                    >{{ emailLink.label }}</a>
                    |
                    <a
                        v-if="phoneLink"
                        :href="phoneLink.url"
                        :target="phoneLink.target"
                        class="font-medium text-xs no-underline ml-2"
                    >{{ phoneLink.label }}</a>
                </div>
            </header-overlay-mobile>
        </fade>
    </header>
</template>

<script>
import Fade from "../transitions/Fade";
import HeaderOverlayMobile from "./HeaderOverlayMobile";

export default {
    name: "HeaderNav",

    components: {
        HeaderOverlayMobile,
        Fade,
    },

    props: {
        currentSiteUrl: {
            type: String,
            required: true,
        },
        logo: {
            type: Object,
            required: true,
        },
        emailLink: {
            type: Object,
            required: true,
        },
        phoneLink: {
            type: Object,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        ariaLabelNavigation: {
            type: String,
            raquired: true,
        },
    },

    data() {
        return {
            hasDarkBackground: false,
            isOverlayOpened: false,
        }
    },

    mounted() {
        this.hasDarkBackground = window.scrollY >= 20;

        window.addEventListener('scroll', this.scrollHandler);
    },

    destroyed() {
        window.removeEventListener('scroll', this.scrollHandler);
    },

    methods: {
        scrollHandler() {
            this.hasDarkBackground = window.scrollY >= 20;
        },
    }
}
</script>

<style scoped lang="scss">
    .header-nav {
        &__banner {
            border-color: rgba(255, 255, 255, 0.1);
        }

        &__logo {
            width: 210px;
            height: 49px;
        }

        &__submenu {
            @apply hidden;
            width: 16.25rem;
        }

        &__submenu-container {
            &:hover,
            &:focus {
                .header-nav__submenu {
                    @apply flex;
                }
            }
        }

        &__desktop-item {
            @apply hover:no-underline opacity-100;

            &::after {
                @apply w-10 bg-transparent absolute left-1/2 transform -translate-x-1/2 bottom-0 transition-colors duration-200;
                content: '';
                height: 0.125rem;
            }

            &:hover {
                &::after {
                    @apply bg-red;
                }
            }
        }
    }
</style>
