<template>
    <div class="anchor relative">
        <div ref="anchor" :id="identifier" class="anchor__element absolute"></div>
    </div>
</template>

<script>
export default {
    name: "Anchor",

    data() {
        return {
            identifier: null,
        }
    },

    props: {
        anchorId: {
            type: String,
            required: true,
        }
    },

    mounted() {
        if (window.location.hash === '#' + encodeURI(this.anchorId)) {
            setTimeout(() => {
                this.identifier = this.anchorId;

                this.$refs.anchor.scrollIntoView();
            }, 1000);
        } else {
            this.identifier = this.anchorId;
        }
    },
}
</script>

<style lang="scss" scoped>
.anchor {
    &__element {
        top: -8.125rem;

        @screen lg {
            top: -8.875rem;
        }
    }
}
</style>
