<template>
    <intersector @enter="enterHandler">
        <slot></slot>
    </intersector>
</template>

<script>
import Intersector from "./Intersector";
import { gsap } from "gsap";

export default {
    name: "AnimateIn",

    props: {
        delay: {
            type: Number,
            default: 0.5,
        },
        initX: {
            type: Number,
            default: 100,
        },
        initY: {
            type: Number,
            default: 0,
        },
        initOpacity: {
            type: Number,
            default: 100,
        },
        duration: {
            type: Number,
            default: 0.4,
        },
        animateChild: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        Intersector,
    },

    computed: {
        element() {
            return this.animateChild ? this.$el.firstElementChild : this.$el;
        }
    },

    mounted() {
        gsap.set(this.element, {
            x: `${this.initX}%`,
            y: `${this.initY}%`,
            opacity: this.initOpacity,
        });
    },

    methods: {
        enterHandler() {
            gsap.to(this.element, {
                x: 0,
                y: 0,
                opacity: 1,
                duration: this.duration,
                delay: this.delay,
                ease: "power2.out",
            });
        },
    }
}
</script>
